import React, { useEffect } from 'react'
import Head from './parts/Head';
import 'simplebar/dist/simplebar.min.css'
import 'swiper/swiper.scss'
import "swiper/modules/navigation/navigation.scss";
import "rc-switch/assets/index.css";
import './App.scss'
import { BrowserRouter as Router } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './App.scss'
import { useRoutes } from './routes'
import { useAuth } from './hooks/auth.hook'
import { AuthContext } from './context/AuthContext'
import MessageContainer from './parts/MessageContainer'
import { MessageContext } from './context/MessageContext'
import { useMessage } from './hooks/message.hook'
import { ChatContext } from './context/ChatContext'
import { useChat } from './hooks/chat.hook'
import { MapContext } from './context/MapContext'
import { useMap } from './hooks/map.hook'
import ResendEmailNotification from './parts/ResendEmailNotification'
import { withGetMe } from './parts/WithGetMe/WithGetMe'
import { useHttp } from './hooks/http.hook';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
  const { login, logout, accessToken, userData, initialized } = useAuth()
  const isAuthenticated = !!accessToken
  const isAdmin = (userData ? userData.is_admin : false)

  const routes = useRoutes(isAuthenticated, isAdmin)

  const { request } = useHttp()

  const {
    lastChatOpened,
    updateLastChat,
    draftMessages,
    updateDraftMessages,
    sendMessageCallBack,
    lastMessage,
    setSocketUrl,
    notifications,
    setNotifications,
    updateNotifications,
    unreadChats,
    setUnreadChats
  } = useChat()
  const { messageData, updateMessage } = useMessage()
  const {
    points,
    visiblePoints,
    loading,
    viewport,
    mapIsVisible,
    mapMoved,
    updatePoints,
    updateVisiblePoints,
    updateLoading,
    setViewport,
    updateMapIsVisible,
    updateMapMoved
  } = useMap()

  const options = {};

  useEffect(() => {
    if (accessToken) {
      setSocketUrl(`${process.env.REACT_APP_SOCKET_URL}/ws/global-chat/?token=${accessToken}`)
      request('/api/users/unreaded_notifications_count')
        .then((resp) => {
          setNotifications(resp.data)
        })
      request('/api/chat')
        .then((resp) => {
          let chatsFiltered = resp.data.results.map((item) => {
            if (!item.last_message.read) {
              return item.id
            }
          })
          chatsFiltered = chatsFiltered.filter(function (element) {
            return element !== undefined;
          });
          setUnreadChats(chatsFiltered)
        })
    }
  }, [accessToken])

  return (
    <>
      <Head />
      <Elements stripe={stripePromise} options={options}>
        <AuthContext.Provider value={{
          login, logout, accessToken, userData, isAuthenticated
        }}>
          <MessageContext.Provider value={{
            type: messageData.type,
            visible: messageData.visible,
            content: messageData.content,
            longerDuration: messageData.longerDuration,
            updateMessage
          }}>
            <ChatContext.Provider value={{
              lastChatOpened, updateLastChat, draftMessages, updateDraftMessages, sendMessageCallBack, lastMessage, setSocketUrl, notifications, setNotifications, updateNotifications, unreadChats, setUnreadChats
            }}>
              <MapContext.Provider value={{
                points, visiblePoints, loading, viewport, mapIsVisible, mapMoved, updatePoints, updateVisiblePoints, updateLoading, setViewport, updateMapIsVisible, updateMapMoved
              }}>
                <Router>
                  <MessageContainer />
                  <ResendEmailNotification />
                  {initialized ? routes : <></>}
                </Router>
              </MapContext.Provider>
            </ChatContext.Provider>
          </MessageContext.Provider>
        </AuthContext.Provider>
      </Elements>
    </>
  )
}

export default withGetMe(App)
