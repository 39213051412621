import { createContext } from 'react'

function noop() { }

export const MessageContext = createContext({
  type: 'error',
  visible: null,
  content: false,
  longerDuration: false,
  updateMessage: noop,
})