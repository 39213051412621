import { useState } from 'react'

export const useMessage = () => {
  const [messageData, setMessageData] = useState({
    type: 'error',
    visible: false,
    content: '',
    longerDuration: false
  })

  const updateMessage = (message) => {
    let type = messageData.type
    let visible = messageData.visible
    let content = messageData.content
    let longerDuration = messageData.longerDuration
    if (!message.type || (message.type && (message.type !== 'error' && message.type !== 'success'))) {
      type = 'error'
    } else {
      type = message.type
    }

    if (!message.longerDuration) {
      longerDuration = false
    } else {
      longerDuration = true
    }

    visible = message.visible
    content = message.content

    setMessageData({
      type: type,
      visible: visible,
      content: content,
      longerDuration: longerDuration,
    })
  }

  return { messageData, updateMessage }
}
