import { createContext } from 'react'

function noop () {}

export const ChatContext = createContext({
  lastChatOpened: 0,
  updateLastChat: noop,
  draftMessages: {},
  updateDraftMessages: noop,
  sendMessageCallBack: noop,
  lastMessage: null,
  setSocketUrl: noop,
  notifications: {},
  setNofications: noop,
  updateNotifications: noop,
  unreadChats: [],
  setUnreadChats: noop,
})
