import React from 'react'
import { Helmet } from "react-helmet";

const Head = () => (
  <Helmet>
    {process.env.REACT_APP_NAME !== 'master' ? (
      <meta name="robots" content="noindex" />
    ) : null}
    {process.env.REACT_APP_NAME === 'master' ? (
      <meta name="keywords" content="estate agent" />
    ) : null}

    {process.env.REACT_APP_NAME === 'master' ? (
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-SL3BVC1R4P"></script>
    ) : null}
    {process.env.REACT_APP_NAME === 'master' ? (
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', 'G-SL3BVC1R4P');
        `}
      </script>
    ) : null}
    {process.env.REACT_APP_NAME === 'master' ? (
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-229387760-1">
      </script>
    ) : null}
    {process.env.REACT_APP_NAME === 'master' ? (
      <script>
        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());

            gtag('config', 'UA-229387760-1');
          `}
      </script>
    ) : null}
  </Helmet >
)

export default Head