import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Loader from './parts/Loader/Loader'

const Sell = lazy(() => import('./pages/Sell'))
const SetUp = lazy(() => import('./pages/SetUp/SetUp'))
const HomePage = lazy(() => import('./pages/Home/Home'))
const Cookie = lazy(() => import('./pages/Cookie/Cookie'))
const Account = lazy(() => import('./pages/Account/Account'))
const FAQPage = lazy(() => import('./pages/FAQPage/FAQPage'))
const PropertiesPage = lazy(() => import('./pages/Properties'))
const UserListings = lazy(() => import('./pages/UserListings'))
const CancelUpdate = lazy(() => import('./parts/User/ActivateUser/CancelUpdate'))
const SavedSearches = lazy(() => import('./pages/SavedSearches/SavedSearches'))
const EditListing = lazy(() => import('./pages/EditListing'))
const BuildingResults = lazy(() => import('./pages/Building/BuildingResults/BuildingResults'))
const EditBuilding = lazy(() => import('./pages/Building/BuildingResults/EditBuilding/EditBuilding'))
const BuildingEntrance = lazy(() => import('./pages/Building/BuildingEntrance/BuildingEntrance'))
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'))
const Chat = lazy(() => import('./pages/Chat/Chat'))
const UpdateUser = lazy(() => import('./parts/User/ActivateUser/UpdateUser'))
const Building = lazy(() => import('./pages/Building/Building'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'))
const ResetPassword = lazy(() => import('./parts/User/ActivateUser/ResetPassword'))
const TermsConditions = lazy(() => import('./pages/TermsConditions/TermsConditions'))
const PublishOnZoopla = lazy(() => import('./pages/PublishOnZoopla/PublishOnZoopla'))
const CalendarRequest = lazy(() => import('./pages/CalendarRequest/CalendarRequest'))
const ActivateEmail = lazy(() => import('./pages/Emails/ActivateEmail/ActivateEmail'))
const FullyVerified = lazy(() => import('./pages/Emails/FullyVerified/FullyVerified'))
const CancelChange = lazy(() => import('./pages/Emails/CancelChange/CancelChange'))
const ConfirmEmail = lazy(() => import('./pages/Emails/ConfirmEmail/ConfirmEmail'))
const PasswordChanged = lazy(() => import('./pages/Emails/PasswordChanged/PasswordChanged'))
const ResetPasswordEmail = lazy(() => import('./pages/Emails/ResetPasswordEmail/ResetPasswordEmail'))
const RequestViewEmail = lazy(() => import('./pages/Emails/RequestViewEmail/RequestViewEmail'))
const RequestAcceptedEmail = lazy(() => import('./pages/Emails/RequestAcceptedEmail/RequestAcceptedEmail'))
const RequestCanceledEmail = lazy(() => import('./pages/Emails/RequestCanceledEmail/RequestCanceledEmail'))
const DeactivatedUserEmail = lazy(() => import('./pages/Emails/DeactivatedUserEmail/DeactivatedUserEmail'))
const ChatRequestIncompletedMobile = lazy(() => import('./parts/ChatRequestIncompletedMobile/ChatRequestIncompletedMobile'))
const PaymentSuccesful = lazy(() => import('./pages/Emails/PaymentSuccesful/PaymentSuccesful'))
const ListingPublished = lazy(() => import('./pages/Emails/ListingPublished/ListingPublished'))
const ZooplaPublished = lazy(() => import('./pages/Emails/ZooplaPublished/ZooplaPublished'))
const PublishOnRightmove = lazy(() => import('./pages/PublishOnRightmove'))
const PublishOnZooplaAndRightmove = lazy(() => import('./pages/PublishOnZooplaAndRightmove'))
const RightmovePublished = lazy(() => import('./pages/Emails/RightmovePublished/RightmovePublished'))
const UserProfile = lazy(() => import('./pages/UserProfile/UserProfile'))
const Favorites = lazy(() => import('./pages/Favorites/Favorites'))
const BiddingOutbidEmail = lazy(() => import('./pages/Emails/BiddingOutbidEmail/BiddingOutbidEmail'))
const BiddingSoldEmail = lazy(() => import('./pages/Emails/BiddingSoldEmail/BiddingSoldEmail'))
const BiddingWinEmail = lazy(() => import('./pages/Emails/BiddingWinEmail/BiddingWinEmail'))
const Bidding = lazy(() => import('./pages/Bidding/Bidding'))

// import SavedSearchEmail from './pages/Emails/SavedSearchEmail/SavedSearchEmail'
// import NewMessageEmail from './pages/Emails/NewMessageEmail/NewMessageEmail'
// import NewsPage from './pages/NewsPage/NewsPage'
// import MarketData from './pages/MarketData/MarketData'

export const useRoutes = (isAuthenticated, isAdmin) => {
  const publicRoutes = [
    {
      path: '/',
      component: HomePage,
      exact: true
    },
    {
      path: '/properties/:listingId?',
      component: PropertiesPage,
      exact: false
    },
    {
      path: '/activate',
      component: HomePage,
      exact: false
    },
    {
      path: '/confirm-update',
      component: UpdateUser,
      exact: false
    },
    // {
    //   path: '/news',
    //   component: NewsPage,
    //   exact: true
    // },
    {
      path: '/faq-page',
      component: FAQPage,
      exact: true
    },
    {
      path: '/cookie',
      component: Cookie,
      exact: true
    },
    {
      path: '/privacy',
      component: PrivacyPolicy,
      exact: true
    },
    {
      path: '/about-us',
      component: AboutUs,
      exact: true
    },
    {
      path: '/cancel-update',
      component: CancelUpdate,
      exact: true
    },
    {
      path: '/user-profile/:email/:listingId?',
      component: UserProfile,
      exact: true
    },
    // {
    //   path: '/market-data',
    //   component: MarketData,
    //   exact: true
    // },
    {
      path: '/reset-password',
      component: ResetPassword,
      exact: true,
    },
    {
      path: '/terms',
      component: TermsConditions,
      exact: true,
    },
    {
      path: '/bidding/:listingId?',
      component: Bidding,
      exact: true,
    },
    {
      path: '/chat-request',
      component: ChatRequestIncompletedMobile,
      exact: true,
    },
    {
      path: '/email/activate-email',
      component: ActivateEmail,
      exact: true,
    },
    {
      path: '/email/fully-verified',
      component: FullyVerified,
      exact: true,
    },
    {
      path: '/email/cancel-change',
      component: CancelChange,
      exact: true,
    },
    {
      path: '/email/confirm-email',
      component: ConfirmEmail,
      exact: true,
    },
    {
      path: '/email/password-changed',
      component: PasswordChanged,
      exact: true,
    },
    {
      path: '/email/reset-password',
      component: ResetPasswordEmail,
      exact: true,
    },
    {
      path: '/email/request-view',
      component: RequestViewEmail,
      exact: true,
    },
    {
      path: '/email/request-accepted',
      component: RequestAcceptedEmail,
      exact: true,
    },
    {
      path: '/email/request-canceled',
      component: RequestCanceledEmail,
      exact: true,
    },
    {
      path: '/email/deactivated-user',
      component: DeactivatedUserEmail,
      exact: true,
    },
    {
      path: '/email/payment-succesful',
      component: PaymentSuccesful,
      exact: false,
    },
    {
      path: '/email/listing-published',
      component: ListingPublished,
      exact: false,
    },
    {
      path: '/email/zoopla-published',
      component: ZooplaPublished,
      exact: false,
    },
    {
      path: '/email/rightmove-published',
      component: RightmovePublished,
      exact: false
    },
    {
      path: '/email/bidding-outbid',
      component: BiddingOutbidEmail,
      exact: false
    },
    {
      path: '/email/bidding-sold',
      component: BiddingSoldEmail,
      exact: false
    },
    {
      path: '/email/bidding-win',
      component: BiddingWinEmail,
      exact: false
    },
    // {
    //   path: '/email/saved-search',
    //   component: SavedSearchEmail,
    //   exact: false
    // },
    // {
    //   path: '/email/new-message',
    //   component: NewMessageEmail,
    //   exact: false
    // }
  ]

  const authRoutes = [
    {
      path: '/user-listings/:id/edit/:step?',
      component: EditListing,
      exact: true
    },
    {
      path: '/user-listings/:id/finish/:step?',
      component: EditListing,
      exact: true
    },
    {
      path: '/user-listings/:id/calendar',
      component: CalendarRequest,
      exact: true
    },
    {
      path: '/user-listings/:id/set-up',
      component: SetUp,
      exact: true
    },
    {
      path: '/buildings/:id/edit',
      component: EditBuilding,
      exact: true
    },
    {
      path: '/user-listings/:listingId?',
      component: UserListings,
      exact: false
    },
    {
      path: '/user-listings',
      component: UserListings,
      exact: true
    },
    {
      path: '/sell/:id?/:step?',
      component: Sell,
      exact: true
    },
    {
      path: '/saved-searches',
      component: SavedSearches,
      exact: true
    },
    {
      path: '/messages',
      component: Chat,
      exact: true
    },
    {
      path: '/messages/:chatId?',
      component: Chat,
      exact: true
    },
    {
      path: '/account',
      component: Account,
      exact: true
    },
    {
      path: '/buildings/create',
      component: Building,
      exact: true
    },
    {
      path: '/buildings',
      component: BuildingResults,
      exact: true
    },
    {
      path: '/entrance',
      component: BuildingEntrance,
      exact: true
    },
    {
      path: '/publish-on-zoopla/:id',
      component: PublishOnZoopla,
      exact: true,
    },
    {
      path: '/publish-on-rightmove/:id',
      component: PublishOnRightmove,
      exact: true,
    },
    {
      path: '/publish-on-zoopla-and-rightmove/:id',
      component: PublishOnZooplaAndRightmove,
      exact: true,
    },
    {
      path: '/favorites',
      component: Favorites,
      exact: true
    },
    {
      path: '/favorites/:listingId?',
      component: Favorites,
      exact: true
    }
  ].concat(publicRoutes)

  const adminRoutes = [
    // {
    //     path: '/create',
    //     component: CreatePage
    // },
    // {
    //     path: '/detail/:id',
    //     component: DetailPage,
    // }
  ].concat(authRoutes)

  if (isAdmin) {
    return (
      <Suspense fallback={<Loader loading={true} />}>
        <Switch>
          {adminRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}

          <Redirect to="/" />
        </Switch>
      </Suspense>
    )
  }

  if (isAuthenticated) {
    return (
      <Suspense fallback={<Loader loading={true} />}>
        <Switch>
          {authRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  )
}
