/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { MessageContext } from '../../context/MessageContext'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import SuccessfulMessage from '../SuccessfulMessage/SuccessfulMessage'

const MessageContainer = () => {
  const message = useContext(MessageContext)

  if (message.type === 'success') {
    return (
      <SuccessfulMessage
        visible={message.visible}
        setProps={message.updateMessage}
        content={message.content}
        longerDuration={message.longerDuration}
      />
    )
  }
  return (
    <ErrorMessage
      visible={message.visible}
      setProps={message.updateMessage}
      content={message.content}
      longerDuration={message.longerDuration}
    />
  )
}

export default MessageContainer