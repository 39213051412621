/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { MessageContext } from '../../context/MessageContext'
import { useHttp } from '../../hooks/http.hook'
import { resendEmailIsVisible, resendNewEmailIsVisible } from '../../utils/messages'
import ResendMessage from '../ResendMessage'

const ResendEmailNotification = () => {
  const auth = useContext(AuthContext)
  const { updateMessage } = useContext(MessageContext)
  const { request, loading } = useHttp()

  const resendEmail = async () => {
    request('/api/auth/resend-verification-email/', 'POST')
      .then((response) => {
        const data = response.data
        auth.login(
          data.token.access_token,
          data.token.refresh_token,
          data.user_data
        )

        if (data && data.user_data && data.user_data.email) {
          showSuccessfulMessage(`Thanks! We’ve sent a confirmation email to ${data.user_data.email}. Click the link in the email to confirm this address.`)
        } else {
          showSuccessfulMessage('Thanks! We’ve sent a confirmation email to your Email. Click the link in the email to confirm this address.')
        }
      })
      .catch((e) => {
        if (e && e.response && e.response.data) {
          if (e.response.data.email) {
            showErrorMessage(`${e.response.data.email[0]}.`)
          } else if (e.response.data.error) {
            showErrorMessage(`${e.response.data.error[0]}.`)
          } else if (e.response.data.new_email) {
            showErrorMessage(`${e.response.data.new_email[0]}.`)
          } else if (e.response.data.message) {
            showErrorMessage(`${e.response.data.message}.`)
          }
        }
      })
  }

  const resendNewEmail = async () => {
    request('/api/auth/resend-confirmation-email/', 'POST')
      .then((response) => {
        const data = response.data
        auth.login(
          data.token.access_token,
          data.token.refresh_token,
          data.user_data
        )

        if (data && data.user_data && data.user_data.new_email) {
          showSuccessfulMessage(`Thanks! We’ve sent a confirmation email to ${data.user_data.new_email}. Click the link in the email to confirm this address.`)
        } else {
          showSuccessfulMessage('Thanks! We’ve sent a confirmation email to your Email. Click the link in the email to confirm this address.')
        }
      })
      .catch((e) => {
        if (e && e.response && e.response.data) {
          if (e.response.data.email) {
            showErrorMessage(`${e.response.data.email[0]}.`)
          } else if (e.response.data.error) {
            showErrorMessage(`${e.response.data.error[0]}.`)
          } else if (e.response.data.new_email) {
            showErrorMessage(`${e.response.data.new_email[0]}.`)
          } else if (e.response.data.message) {
            showErrorMessage(`${e.response.data.message}.`)
          }
        }
      })
  }

  const showSuccessfulMessage = (message) => {
    updateMessage({
      type: "success",
      visible: true,
      content: message,
    });
  }

  const showErrorMessage = (errorMessage) => {
    updateMessage({
      visible: true,
      content: errorMessage,
    })
  }

  if (resendEmailIsVisible(auth)) {
    return (
      <ResendMessage
        loading={loading}
        title="You have nearly completed your registration, check your email."
        resendEmail={resendEmail}
        resendBtnText="Resend activation"
      />
    )
  }
  if (resendNewEmailIsVisible(auth)) {
    return (
      <ResendMessage
        loading={loading}
        title="We’ve sent a confirmation message to your email, check your email."
        resendEmail={resendNewEmail}
        resendBtnText="Resend confirmation"
      />
    )
  }
  return <></>
}

export default ResendEmailNotification
