/* eslint-disable react/prop-types */
import React from 'react'

import "./Loader.scss"

export default function Loader({
  loading,
  content,
  fullWidth,
  fullHeight,
}) {
  if (!loading) return <></>
  return (
    <div
      data-testid='loader'
      className={`loader-component center ${fullWidth ? 'w-100' : ''} ${fullHeight ? 'h-100' : ''}`}
    >
      <div
        data-testid='loader-icon'
        className="loader-component-icon" />
      {
        content
          ? (
            <p
              data-testid='loader-text'
              className="text">{content}</p>
          )
          : <></>
      }
    </div>
  )
}