import { useCallback, useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'
import { useAuth } from './auth.hook'

const storageName = 'MoovingUserChatData'

export const useChat = () => {
  const auth = useAuth()
  const [lastChatOpened, setLastChatOpened] = useState(0)
  const [draftMessages, setDraftMessages] = useState({})
  const [socketUrl, setSocketUrl] = useState(`${process.env.REACT_APP_SOCKET_URL}/ws/`);
  const [notifications, setNotifications] = useState({
    message: 0,
    listing: 0,
    saved_search: 0,
    account: 0,
    total: 0,
  })
  const [unreadChats, setUnreadChats] = useState([])
  const { sendMessage, lastMessage } = useWebSocket(socketUrl);

  const sendMessageCallBack = useCallback((data) => {
    sendMessage(JSON.stringify(data));
  }, []);

  const updateLastChat = (lastChat) => {
    setLastChatOpened(lastChat)
  }

  const updateDraftMessages = useCallback((data) => {
    setDraftMessages(data)

    localStorage.setItem(storageName, JSON.stringify({
      draftMessages: data,
    }))
  }, [])

  const updateNotifications = (name, count) => {
    let newNotifications = notifications

    if (newNotifications[name] - count > -1) {
      newNotifications[name] = newNotifications[name] - count
      newNotifications.total = newNotifications.total - count
    }

    setNotifications({ ...newNotifications })
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))

    if (data && data.draftMessages) {
      updateDraftMessages(data.draftMessages)
    }
  }, [updateDraftMessages])

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      if (unreadChats.includes(JSON.parse(lastMessage.data).id))
        return
      if (JSON.parse(lastMessage.data) && JSON.parse(lastMessage.data).id && JSON.parse(lastMessage.data).id === lastChatOpened && window.location.pathname === '/messages')
        return
      if (
        auth && auth.userData && auth.userData.email && 
        JSON.parse(lastMessage.data).last_message && JSON.parse(lastMessage.data).last_message.sender_email && 
        auth.userData.email === JSON.parse(lastMessage.data).last_message.sender_email
      )
        return
      switch (JSON.parse(lastMessage.data).notification_type) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          setNotifications(prev => {
            return {
              ...prev,
              message: notifications.message + 1,
              total: notifications.total + 1
            }
          })
          unreadChats.push(JSON.parse(lastMessage.data).id)
          break;
        case 7:
          setNotifications(prev => {
            return {
              ...prev,
              saved_search: notifications.saved_search + 1,
              total: notifications.total + 1
            }
          })
          break;
        case 8:
          setNotifications(prev => {
            return {
              ...prev,
              listing: notifications.listing + 1,
              total: notifications.total + 1
            }
          })
          break;
        case 9:
          setNotifications(prev => {
            return {
              ...prev,
              account: notifications.account + 1,
              total: notifications.total + 1
            }
          })
          break;
        default:
          break;
      }
    }
  }, [lastMessage])

  return { 
    lastChatOpened, 
    updateLastChat, 
    draftMessages, 
    updateDraftMessages, 
    sendMessageCallBack, 
    lastMessage, 
    setSocketUrl, 
    notifications, 
    setNotifications, 
    updateNotifications, 
    unreadChats, 
    setUnreadChats 
  }
}
