import React, { useEffect, useState } from 'react'
import { userDataStorageName } from '../../constants/localStorage';
import { useAuth } from '../../hooks/auth.hook';
import { useHttp } from "../../hooks/http.hook"
import Loader from '../Loader/Loader';

export const withGetMe = (Component) => {
  return function WrappedComponent(props) {
    const { request } = useHttp();
    const { login } = useAuth()//use hook because if I wrap App - this component don't know about context from auth
    const [response, setResponse] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const getMe = async () => {
      setLoading(true)
      const userData = JSON.parse(localStorage.getItem(userDataStorageName))
      if (userData) {
        try {
          const resp = await request('/api/users/get-me/', 'GET')
          if (resp && resp.data && resp.data.user_data && resp.data.token) {
            login(
              resp.data.token.access_token,
              resp.data.token.refresh_token,
              resp.data.user_data
            )
          }
          setResponse(resp)
        } catch (e) {
          console.warn(e && e.response && e.response.data)
          setError(e)
        }
      }
      setLoading(false)
    }

    useEffect(() => {
      getMe();
    }, [])

    if (loading) return <Loader loading={loading} />
    return <Component getMeResponse={response} getMeError={error} {...props} />;
  };
};