import { useState, useCallback, useEffect } from 'react'
import { userDataStorageName } from '../constants/localStorage'

export const useAuth = () => {
  const [accessToken, setAccessToken] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [refreshToken, setRefreshToken] = useState(null)
  const [userData, setUserData] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const login = useCallback((jwtAccessToken, jwtRefreshToken, data) => {
    setUserData(data)
    setAccessToken(jwtAccessToken)
    setRefreshToken(jwtRefreshToken)

    localStorage.setItem(userDataStorageName, JSON.stringify({
      accessToken: jwtAccessToken,
      refreshToken: jwtRefreshToken,
      userData: data,
    }))
  }, [])

  const logout = useCallback(() => {
    setAccessToken(null)
    setRefreshToken(null)
    setUserData(null)

    localStorage.removeItem(userDataStorageName)
  }, [])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(userDataStorageName))

    if (data && data.userData && data.accessToken && data.refreshToken) {
      login(data.accessToken, data.refreshToken, data.userData)
      if (!initialized) {
        setInitialized(true)
      }
    } else {
      logout()
      if (!initialized) {
        setInitialized(true)
      }
    }
  }, [login])
  return { login, logout, accessToken, userData, initialized }
}
