/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import './ErrorMessage.scss'

export default function ErrorMessage({ visible, setProps, content, longerDuration }) {
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        closeMessage()
      }, longerDuration ? 8900 : 6900)
    }
  }, [visible])

  const closeMessage = () => {
    setProps({
      visible: false,
      content: '',
      longerDuration: 0
    })
  }

  return (
    <div className={`${visible ? "error-message-component" : 'hide'}${visible && longerDuration ? " nine-sec-animation" : (visible ? " seven-sec-animation" : '')}`}>
      <div className="body">
        <div className="left">
          <img src="/images/error_outline.svg" alt="error icon" />
          <p className='text'>{content}</p>
        </div>
        <div className="close" onClick={() => closeMessage()}>
          <img src="/images/white_close_big.svg" alt='' />
        </div>
      </div>
    </div>
  )
}