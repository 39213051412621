/* eslint-disable react/prop-types */
import React from 'react'

import './ResendMessage.scss'

const ResendMessage = ({ loading, resendEmail, title, resendBtnText }) => (
  <div
    data-cy="resend-email-message-component"
    className="resend-message-component"
  >
    <div className="body">
      <p className="resend-message-component-title">{title}</p>
      <button
        onClick={() => !loading ? resendEmail() : () => { }}
        className={`resend-message-component-btn${loading ? ' resend-message-component-btn-disabled' : ''}`}
      >
        {resendBtnText}
      </button>
    </div>
  </div>
)

export default ResendMessage