import { createContext } from "react";

function noop() { }

export const MapContext = createContext({
  points: [],
  visiblePoints: [],
  loading: false,
  viewport: null,
  mapIsVisible: false,
  mapMoved: false,
  updatePoints: noop,
  updateVisiblePoints: noop,
  updateLoading: noop,
  setViewport: noop,
  updateMapIsVisible: noop,
  updateMapMoved: noop,
})