import { useState } from "react";

export const useMap = () => {
  const [points, setPoints] = useState([])
  const [visiblePoints, setVisiblePoints] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewport, setViewport] = useState({
    longitute: 0,
    latitude: 0,
    zoom: 0,
  })
  const [mapIsVisible, setMapIsVisible] = useState(false)
  const [mapMoved, setMapMoved] = useState(false)

  const updatePoints = (points) => {
    setPoints(points)
  }

  const updateVisiblePoints = (points) => {
    setVisiblePoints(points)
  }

  const updateLoading = (isLoading) => {
    setLoading(isLoading)
  }

  const updateMapIsVisible = (state) => {
    if (mapIsVisible)
      setMapMoved(false)
    setMapIsVisible(state)
  }
  
  const updateMapMoved = (state) => {
    setMapMoved(state)
  }

  return {
    points,
    visiblePoints,
    loading,
    viewport,
    mapIsVisible,
    mapMoved,
    updatePoints,
    updateVisiblePoints,
    updateLoading,
    setViewport,
    updateMapIsVisible,
    updateMapMoved,
  }
}