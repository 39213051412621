import { useState, useCallback } from 'react'
import axios from 'axios'

axios.interceptors.request.use(request => {

  const userData = JSON.parse(localStorage.getItem('MoovingUserData'))
  if (userData !== null) {
    request.headers['Authorization'] = `Bearer ${userData['accessToken']}`
  }

  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return new Promise(async (resolve, reject) => {
      const originalRequest = error.config
      const userData = JSON.parse(localStorage.getItem('MoovingUserData'))

      if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest && userData) {
        originalRequest._retry = true

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh-token/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refresh_token: userData['refreshToken']
          })
        })
          .then(resp => resp.json())
          .then((json) => {
            userData['accessToken'] = json.token.access_token
            userData['refreshToken'] = json.token.refresh_token

            localStorage.setItem('MoovingUserData', JSON.stringify(userData))

            return axios(originalRequest)

          })
          .catch((e) => {
            localStorage.removeItem('MoovingUserData')
            window.location.assign('/')
            return reject(error)
          })

        resolve(response)
      }

      return reject(error)
    })
  }
);

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const request = useCallback(async (url, method = 'GET', data = null, headers = {}) => {
    setLoading(true)
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}${url}`, { method, data, headers })
      setLoading(false)
      return response
    } catch (e) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = () => setError(null)

  return { loading, request, error, clearError }
}
