/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import './SuccessfulMessage.scss'

export default function SuccessfulMessage({ visible, setProps, content, longerDuration }) {
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        closeMessage()
      }, longerDuration ? 8900 : 6900)
    }
  }, [visible])

  const closeMessage = () => {
    if (setProps && (typeof setProps === 'function')) {
      setProps({
        visible: false,
        content: '',
        longerDuration: false
      })
    }
  }

  if (!content) return <></>
  return (
    <div
      data-testid="successfull-message-component"
      className={`${visible ? "successfull-message-component" : 'hide'}${visible && longerDuration ? " nine-sec-animation" : (visible ? " seven-sec-animation" : '')}`}
    >
      <div className="body">
        <p
          data-testid="successfull-message-component-title"
          className="successfull-message-component-title"
        >{content}</p>
        <div
          data-testid="successfull-message-component-hide"
          className="successfull-message-component-hide"
          onClick={() => closeMessage()}>
          <img src="/images/white_close_big.svg" alt='' />
        </div>
      </div>
    </div>
  )
}